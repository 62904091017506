<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="演出" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入演出"></a-input>
					</a-form-item>
					
					<a-form-item label="票档" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入票档"></a-input>
					</a-form-item>
					
					<a-form-item label="票号" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入票号"></a-input>
					</a-form-item>
					
					<a-form-item label="订单号" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入订单号"></a-input>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="16">
						<a-button type="primary" style="margin-right: 10px;">导出</a-button>
						<a-button type="primary" @click="onImport">分配座位导入</a-button>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 10px;">
				<cTable ref="cTable" style="padding: 0;" :searchData="searchData" :columns="columns" :isPage="true" :isRequest="true" :requestFun="getPerformOrderList" :scroll="{ x: 3500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'showStartTime'">
							{{ transDateTime(record.showStartTime) }}
						</template>
						<template v-if="column.key === 'user'">
							<div>
								<div>用户id：{{ record.user.memberId || '-' }}</div>
								<div>用户名称：{{ record.user.nickname || '-' }}</div>
								<div>用户手机号：{{ record.user.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'payType'">
							<div>
								<a-tag v-if="record.payType === 'wx_lite'" color="#87d068">微信支付</a-tag>
								<a-tag v-if="record.payType === 'alipay'" color="#2db7f5">支付宝支付</a-tag>
								<a-tag v-if="record.payType === 'card'" color="#f50">一卡通支付</a-tag>
							</div>
						</template>
						<template v-if="column.key === 'status'">
						</template>
						<template v-if="column.key === 'sendInfo'">
							<div v-if="record.deliveryType === 3">
								<div>物流公司：{{ record.logisticsCompany }}</div>
								<div>物流单号：{{ record.logisticsNo }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'afterInfo'">
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div @click="onAfter(record)">
											<a-menu-item>
												分配座位
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</cTable>
			</div>

			<a-modal v-model:visible="showAfterModal" destroyOnClose title="分配座位" width="500px">
				<a-spin :spinning="loading">
					<a-form ref="afterForm" scrollToFirstError :model="afterModelRef" name="afterForm"
						:labelCol="{span: 5}" :wrapperCol="{span: 15 }">
						
						<a-form-item label="座位号" name="price" :rules="[{required: true, message:'必填项不允许为空'}]">
							<a-input v-model:value="afterModelRef.price"></a-input>
						</a-form-item>
						
						<a-form-item label="座位编码" name="info" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="afterModelRef.info"></a-input>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
			
			<a-modal v-model:visible="importModal" title="分配座位导入" width="759px" @ok="onImportOk">
				<a-spin :spinning="loading">
					<a-form ref="importFormRef" :model="importModelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }">
						<div>
							<a-form-item label="任务名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input v-model:value="importModelRef.title" placeholder="请输入"></a-input>
							</a-form-item>
							
							<a-form-item label="点击下载">
								<a href="/dashboard/导入演出场次分配座位模板.xlsx">Excel模板</a>
							</a-form-item>
							
							<a-form-item label="选择文件">
								<a-upload v-model:file-list="fileList" list-type="picture" action="/admin/ajaxUpload.do"
										  accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload" @change="onUploadChange">
									<a-button>
										<Icon icon="UploadOutlined"></Icon>
										上传
									</a-button>
								</a-upload>
							</a-form-item>
						</div>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>

	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import logisticsCompany from '@/components/logisticsCompany/index.vue';
	import { getPerformOrderList } from '@/service/modules/perform.js';
	import { sendOrderBase } from '@/service/modules/mall.js';
	import { importTaskSave } from '@/service/modules/task.js';
	export default {
		components: {
			Icon,
			cTable,
			logisticsCompany
		},
		data() {
			return {
				showSendGoods: false,
				showAfterModal: false,
				loading: false,
				searchData: {},
				afterModelRef: {},
				formState: {
					sale: 0
				},
				columns: [{
					title: '演出',
					dataIndex: 'performName',
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 170
				}, {
					title: '场次时间',
					key: 'showStartTime'
				}, {
					title: '票档',
					dataIndex: 'ticketName',
				}, {
					title: '用户信息',
					key: 'user',
					width: 220
				}, {
					title: '票号'
				}, {
					title: '座位'
				}, {
					title: '座位编码'
				}, {
					title: '分配时间'
				}, {
					title: '分配人'
				}, {
					title: '座位状态'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
				importModal: false,
				importModelRef: {},
				fileList: []
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getPerformOrderList: getPerformOrderList,
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData(true);
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onAfter(item) {
				this.afterModelRef = {
					id: item.id
				}
				this.showAfterModal = true;
			},
			onSendOrder(item) {
				this.modelRef = {
					id: item.id,
					logisticsCompany: undefined,
					logisticsNo: ''
				}
				this.showSendGoods = true;
			},
			onImport() {
				this.importModelRef = {
					type: 'performSeatDivideImportStrategy'
				};
				this.importModal = true;
			},
			onImportOk() {
				this.$refs.importFormRef.validate().then(() => {
					if (this.fileList.length === 0) {
						this.$message.warn('请先选择模板文件');
						return;
					}
					this.$confirm({
						title: '提示',
						content: '确定执行导入吗？',
						onOk: async () => {
							this.loading = true;
							let postData = JSON.parse(JSON.stringify(this.importModelRef));
							let ret = await importTaskSave(postData)
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请前往【系统-任务中心-导入任务】页查看导入结果');
								this.importModal = false;
								this.importModelRef = {};
							}
						}
					})
				})
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.importModelRef.filePath = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 10;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许10M');
				}
				return isLt1M;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>